import React, { useState } from "react";

export default function useApi(api) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const request = async (...payload) => {
    console.log("request has been make");
    setLoading(true);
    try {
      const temp = await api(...payload);
      setData(temp);
      console.log(temp);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      console.log("finished");
      setLoading(false);
    }
  };

  return { loading, data, error, request };
}

import React from "react";

import TabContent from "./TabContent";
import TabLinks from "./TabLinks";

export default function Tab({ tabs }) {
  return (
    <div className="tabs">
      <TabLinks tabs={tabs} />
      <TabContent tabs={tabs} />
    </div>
  );
}

import { getDocs, collection, query, limit, orderBy } from "firebase/firestore";

import { firestore } from "../firebase/firebase";

const ref = collection(firestore, "currently_showing");

export async function getCurrenlyShowing(movieLimit) {
  const shows = [];

  const q = query(ref, orderBy("index", "asc"), limit(movieLimit));
  const data = await getDocs(q);

  data.docs.forEach((item) => shows.push({ docId: item.id, ...item.data() }));

  return shows;
}

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Tab from "../components/Tab";

import withHeaderFooter from "../hoc/withHeaderFooter";
import MovieTab from "./tabs/MovieTab";

import {
  loadUpcomingMovies,
  getUpcomingMoviesList,
  getUpcomingMovieLoading,
} from "../stores/entities/upcomingMovies";

import {
  loadCurrentlyShowings,
  getCurrenlyShowingList,
  getCurrenlyShowingLoading,
} from "../stores/entities/currentlyShowingMovies";
import Shimmer from "../components/Shimmer";
import Loading from "./tabs/Loading";

function HomePage() {
  const dispatch = useDispatch();
  const currenlyShowing = useSelector(getCurrenlyShowingList);
  const upcomingMovies = useSelector(getUpcomingMoviesList);
  const currentLoading = useSelector(getCurrenlyShowingLoading);
  const upcomingLoading = useSelector(getUpcomingMovieLoading);
  const navigate = useNavigate();

  const handleMovieClick = (movie) =>
    navigate(
      `/movie/${movie.bms_name.toString().toLowerCase().replaceAll(" ", "-")}`,
      { state: movie }
    );

  const tabs = [
    {
      id: "tab1",
      label: "Currently Showing",
      content: () => (
        <>
          {upcomingLoading ? (
            <Loading />
          ) : (
            <MovieTab
              movies={_.take(currenlyShowing, 12)}
              onMovieItemClick={handleMovieClick}
            />
          )}
        </>
      ),
    },
    {
      id: "tab2",
      label: "Coming soon",
      content: () => (
        <>
          {upcomingLoading ? (
            <Loading />
          ) : (
            <MovieTab
              movies={_.take(upcomingMovies, 12)}
              onMovieItemClick={handleMovieClick}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadCurrentlyShowings());
    dispatch(loadUpcomingMovies());
  }, []);

  return (
    <>
      <div className="movie-items">
        <div className="container">
          <div className="row ipad-width">
            <div className="col-md-12">
              <div className="title-hd">
                <h2>in theater</h2>
                <Link to="/movies" className="viewall">
                  View all <i className="ion-ios-arrow-right" />
                </Link>
              </div>

              <Tab tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withHeaderFooter(HomePage);

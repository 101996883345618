import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MovieListItem from "../components/common/MovieListItem";

import withHeaderFooter from "../hoc/withHeaderFooter";

import {
  getCurrenlyShowingList,
  loadCurrentlyShowings,
} from "../stores/entities/currentlyShowingMovies";

function MoviesListPage() {
  const dispatch = useDispatch();
  const data = useSelector(getCurrenlyShowingList);

  useEffect(() => {
    dispatch(loadCurrentlyShowings());
  }, []);

  return (
    <div className="page-single movie_list mt-5">
      <div className="container">
        <div className="row ipad-width2">
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="topbar-filter">
              <p>
                Found <span>{data?.length}</span> in total
              </p>
              {/* <label>Sort by:</label>
              <select>
                <option value="popularity">Popularity Descending</option>
                <option value="popularity">Popularity Ascending</option>
                <option value="rating">Rating Descending</option>
                <option value="rating">Rating Ascending</option>
                <option value="date">Release date Descending</option>
                <option value="date">Release date Ascending</option>
              </select> */}
              {/* <a href="movielist.html" className="list">
                <i className="ion-ios-list-outline active" />
              </a>
              <a href="moviegrid.html" className="grid">
                <i className="ion-grid" />
              </a> */}
            </div>
            {data?.map((movie) => (
              <MovieListItem
                key={movie.docId}
                thumbnail={movie.bms_poster_image_url}
                name={movie.bms_name}
                rate={movie.bms_rated}
                synopsis={movie.synopsis}
              />
            ))}

            {/* <div className="topbar-filter">
              <label>Movies per page:</label>
              <select>
                <option value="range">5 Movies</option>
                <option value="saab">10 Movies</option>
              </select>
              <div className="pagination2">
                <span>Page 1 of 2:</span>
                <a className="active" href="#">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">
                  <i className="ion-arrow-right-b" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withHeaderFooter(MoviesListPage);

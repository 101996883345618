import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout";
import MovieDetailPage from "./pages/MovieDetailPage";
import MoviesListPage from "./pages/MoviesListPage";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<HomePage />} />
        <Route path="movie/:name" element={<MovieDetailPage />} />
        <Route path="movies" element={<MoviesListPage />} />
      </Route>
    </Routes>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import moment from "moment/moment";
import { apiCallBegin } from "../api";

import { getCurrenlyShowing } from "../../services/currenlyShowing";
import { getUpcomingMovies } from "../../services/upcomingMovies";

const slice = createSlice({
  name: "upcoming",
  initialState: {
    list: [],
    lastFetch: null,
    loading: false,
  },
  reducers: {
    upcomingRequested: (state, action) => {
      state.loading = true;
      state.list = [];
    },

    upcomingRequestFailed: (state, action) => {
      state.loading = false;
    },

    upcomingRequestedRecieved: (state, action) => {
      state.list = action.payload;
      state.loading = false;
      state.lastFetch = Date.now();
    },
  },
});

export const {
  upcomingRequestFailed,
  upcomingRequested,
  upcomingRequestedRecieved,
} = slice.actions;
export default slice.reducer;

export const loadUpcomingMovies = (args) => (dispatch, getState) => {
  const { lastFetch } = getState().upcoming;
  console.log(lastFetch);

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10 && args !== null) return;

  dispatch(
    apiCallBegin({
      firebase: getUpcomingMovies,
      onStart: upcomingRequested.type,
      onSuccess: upcomingRequestedRecieved.type,
      onError: upcomingRequestFailed.type,
      args,
    })
  );
};

export const getUpcomingMoviesList = (state) => state?.upcoming?.list;

export const getUpcomingMovieLoading = (state) => state?.upcoming?.loading;

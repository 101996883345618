import React from "react";

export default function Footer() {
  return (
    <footer className="ht-footer">
      <div className="container">
        <div className="flex-parent-ft">
          <div className="flex-child-ft item5 text-center">
            <h4>Newsletter</h4>
            <p>
              Subscribe to our newsletter system now to get latest news from us.
            </p>
            <form action="#">
              <input type="text" placeholder="Enter your email..." />
            </form>
            <a href="#" className="btn">
              Subscribe now <i className="ion-ios-arrow-forward" />
            </a>
          </div>
        </div>
      </div>
      <div className="ft-copyright">
        <div className="ft-left">
          <p>© {new Date().getFullYear()} FanOfAction. All Rights Reserved.</p>
        </div>
        <div className="backtotop">
          <p>
            <a href="#" id="back-to-top">
              Back to top <i className="ion-ios-arrow-thin-up" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

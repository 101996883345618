import React from "react";

/**
 *
 * @param tabs interface
 * @returns
 */

export default function TabContent({ tabs }) {
  return (
    <div className="tab-content">
      {tabs.map((tab, index) => (
        <div
          key={tab.id}
          id={tab.id}
          className={`tab ${index === 0 ? "active" : ""}`}
        >
          {tab.content()}
        </div>
      ))}
    </div>
  );
}

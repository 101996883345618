import React from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";

export default function withHeaderFooter(Component) {
  return (props) => {
    return (
      <>
        <Header />
        <Component {...props} />
        <Footer />
      </>
    );
  };
}

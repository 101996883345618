import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import moment from "moment/moment";
import { apiCallBegin } from "../api";

import { getCurrenlyShowing } from "../../services/currenlyShowing";

const slice = createSlice({
  name: "currentlyShowing",
  initialState: {
    list: [],
    lastFetch: null,
    loading: false,
  },
  reducers: {
    showRequested: (state, action) => {
      state.loading = true;
      state.list = [];
    },

    showRequestFailed: (state, action) => {
      state.loading = false;
    },

    showRequestedRecieved: (state, action) => {
      state.list = action.payload;
      state.loading = false;
      state.lastFetch = Date.now();
    },

    addCurrentlyShowing: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const {
  addCurrentlyShowing,
  showRequestFailed,
  showRequested,
  showRequestedRecieved,
} = slice.actions;
export default slice.reducer;

export const loadCurrentlyShowings = (args) => (dispatch, getState) => {
  const { lastFetch } = getState().theaters;
  console.log(lastFetch);

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10 && args !== null) return;

  dispatch(
    apiCallBegin({
      firebase: getCurrenlyShowing,
      onStart: showRequested.type,
      onSuccess: showRequestedRecieved.type,
      onError: showRequestFailed.type,
      args,
    })
  );
};

export const getCurrenlyShowingList = (state) => state?.theaters?.list;

export const getCurrenlyShowingLoading = (state) => state?.upcoming?.loading;

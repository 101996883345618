import * as actions from "../api";

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegin.type) return next(action);

    var {
      url,
      method,
      headers,
      data,
      body,
      firebase,
      onStart,
      onSuccess,
      onError,
      args,
    } = action.payload;

    if (onStart) dispatch({ type: onStart });
    next(action);

    try {
      const data = await firebase(args);

      // General Success
      dispatch(actions.apiCallSuccess(data));

      // Specific
      if (onSuccess) {
        if (Array.isArray(onSuccess)) {
          onSuccess.forEach((success) => {
            dispatch({ type: success, payload: data });
          });
        } else {
          dispatch({ type: onSuccess, payload: data });
        }
      }
    } catch (error) {
      // Generic Error
      dispatch(actions.apiCallFailed(error));

      // Specific Error
      if (onError) {
        if (Array.isArray(onError)) {
          onError.forEach((err) =>
            dispatch({
              type: err,
              payload: error,
            })
          );
        } else {
          dispatch({
            type: onError,
            payload: error,
          });
        }
      }
    }
  };

export default api;

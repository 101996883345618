import React from "react";

export default function MovieItem({
  movieName,
  rating,
  thumbnail,
  onReadMore,
}) {
  return (
    <div className="movie-item">
      <div className="mv-img">
        <img src={thumbnail} alt="" />
      </div>
      <div className="hvr-inner">
        <a onClick={onReadMore}>
          <span
            className="me-2 pe-2 d-inline-block"
            style={{ marginRight: ".5em" }}
          >
            Read more
          </span>{" "}
          {"  "}
          <i className="ion-android-arrow-dropright ms-2" />
        </a>
      </div>
      <div className="title-in">
        <h6>
          <a>{movieName}</a>
        </h6>
        <p>
          {/* <i className="ion-android-star" /> */}
          <span>{rating}</span>
        </p>
      </div>
    </div>
  );
}

import React from "react";

export default function MovieListItem({
  thumbnail,
  name,
  year,
  rate,
  describe,
  runTime,
  director,
  releaseDate,
  synopsis,
}) {
  return (
    <div className="movie-item-style-2">
      <img src={thumbnail} alt="" />
      <div className="mv-item-infor">
        <h6>
          <a>
            {name}
            {/* {name} <span>(2012)</span> */}
          </a>
        </h6>
        {/* <p className="rate">
          <i className="ion-android-star" />
          <span>8.1</span> /10
        </p> */}
        <p className="describe">{synopsis}</p>
        {/* <p className="run-time">
          Run Time: 2h 30m .<span>MMPA: {rate} </span> .
          <span>Release: 11 Oct, 2022</span>
        </p>
        <p>
          Director: <a>{director}</a>
        </p>
        <p>
          Stars: <a href="#">Robert Downey Jr.,</a> <a href="#">Chris Evans,</a>{" "}
          <a href="#"> Chris Hemsworth</a>
        </p> */}
      </div>
    </div>
  );
}

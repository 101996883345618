import { getDocs, collection, query, limit } from "firebase/firestore";

import { firestore } from "../firebase/firebase";

const ref = collection(firestore, "upcoming_movies");

export async function getUpcomingMovies(movieLimit) {
  const shows = [];

  const q = query(ref, limit(movieLimit));
  const data = await getDocs(q);

  data.docs.forEach((item) => shows.push({ docId: item.id, ...item.data() }));

  return shows;
}

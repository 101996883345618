import {
  getDocs,
  collection,
  query,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";

import { firestore } from "../firebase/firebase";

const COLLECTION_NAME = "movie_details";

const ref = collection(firestore, COLLECTION_NAME);

export async function getAllMovieDetails() {
  const shows = [];

  const q = query(ref, limit());
  const data = await getDocs(q);

  data.docs.forEach((item) => shows.push({ docId: item.id, ...item.data() }));

  return shows;
}

export async function getMovieDetailById(docId) {
  const docRef = doc(firestore, COLLECTION_NAME, docId);

  const docSnap = await getDoc(docRef);
  const data = docSnap.exists() ? docSnap.data() : null;

  return { docId, ...data };
}

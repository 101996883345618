import React from "react";

/**
 * interface {
 *   label, id
 * }
 * @returns
 */
export default function TabLinks({ tabs }) {
  return (
    <ul className="tab-links">
      {tabs.map((tab, index) => (
        <li className={index === 0 ? "active" : ""} key={tab.id}>
          <a href={`#${tab.id}`}>#{tab.label}</a>
        </li>
      ))}
    </ul>
  );
}

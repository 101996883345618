import React, { useRef } from "react";
import _ from "lodash";
import Slider from "react-slick";

import MovieItem from "../../components/common/MovieItem";
import Shimmer from "../../components/Shimmer";

export default function MovieTab({ movies, onMovieItemClick }) {
  const ref = useRef();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  if (!movies) return null;

  return (
    <div className="row">
      <Slider {...settings} ref={ref}>
        {movies.map((movie) => (
          <div className="slide-it" key={movie.docId}>
            <MovieItem
              movieName={movie.bms_name}
              thumbnail={movie.bms_poster_image_url}
              rating={movie.bms_rated}
              onReadMore={() => onMovieItemClick(movie)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
